var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home-page" },
    [
      _c("zui-header"),
      _c("div", { staticClass: "banner-box" }, [
        _c("img", {
          staticStyle: { "margin-top": "-100px" },
          attrs: {
            src: "https://www.achencybe.com/img/pc/others/investors_banner.png",
            width: "100%",
          },
        }),
        _c("div", { staticClass: "text" }, [
          _c("div", { staticClass: "text-1" }, [
            _vm._v(_vm._s(_vm.$t("investors.p1"))),
          ]),
          _c("div", { staticClass: "text-2" }, [_vm._v("1+1>2")]),
        ]),
        _vm._m(0),
      ]),
      _c("div", { staticClass: "text-box" }, [
        _c("div", [_vm._v(_vm._s(_vm.$t("investors.p2")))]),
        _c("div", [_vm._v(_vm._s(_vm.$t("investors.p3")))]),
        _c("div", [_vm._v(_vm._s(_vm.$t("investors.p4")))]),
        _c("div", [_vm._v(_vm._s(_vm.$t("investors.p5")))]),
        _c("div", [_vm._v(_vm._s(_vm.$t("investors.p6")))]),
      ]),
      _c("div", { staticClass: "banner-text" }, [
        _c("div", [_vm._v(_vm._s(_vm.$t("investors.p7")))]),
        _c("div", [_vm._v(_vm._s(_vm.$t("investors.p8")))]),
      ]),
      _c(
        "div",
        {
          staticStyle: {
            width: "100%",
            padding: "30px 0px",
            "text-align": "center",
          },
        },
        [
          _c(
            "a",
            {
              staticStyle: { "text-decoration": "underline" },
              on: { click: _vm.goToForm },
            },
            [_vm._v(_vm._s(_vm.$t("investors.p9")))]
          ),
        ]
      ),
      _c("zui-footer"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img" }, [
      _c("img", {
        staticClass: "logo",
        attrs: { src: "https://www.achencybe.com/img/pc/logo.png", alt: "" },
      }),
      _c("img", {
        staticClass: "besa",
        attrs: {
          src: "https://www.achencybe.com/img/pc/others/investors_besa.png",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }