import { render, staticRenderFns } from "./InvestorsPage.vue?vue&type=template&id=67115249&scoped=true&"
import script from "./InvestorsPage.vue?vue&type=script&lang=js&"
export * from "./InvestorsPage.vue?vue&type=script&lang=js&"
import style0 from "./InvestorsPage.vue?vue&type=style&index=0&id=67115249&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67115249",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/matengyu/个人/bwm/CarModifyWeb/sellgit/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('67115249')) {
      api.createRecord('67115249', component.options)
    } else {
      api.reload('67115249', component.options)
    }
    module.hot.accept("./InvestorsPage.vue?vue&type=template&id=67115249&scoped=true&", function () {
      api.rerender('67115249', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/public/InvestorsPage.vue"
export default component.exports