<template>
  <div class="home-page">
    <zui-header />
    <div class="banner-box">
      <img src="https://www.achencybe.com/img/pc/others/investors_banner.png" width="100%" style="margin-top:-100px;" />
      <div class="text">
        <div class="text-1">{{ $t('investors.p1') }}</div>
        <div class="text-2">1+1>2</div>
      </div>
      <div class="img">
        <img class="logo" src="https://www.achencybe.com/img/pc/logo.png" alt="">
        <img class="besa" src="https://www.achencybe.com/img/pc/others/investors_besa.png">
      </div>
    </div>

    <div class="text-box">
      <div>{{ $t('investors.p2') }}</div>
      <div>{{ $t('investors.p3') }}</div>
      <div>{{ $t('investors.p4') }}</div>
      <div>{{ $t('investors.p5') }}</div>
      <div>{{ $t('investors.p6') }}</div>
    </div>
    <div class="banner-text">
      <div>{{ $t('investors.p7') }}</div>
      <div>{{ $t('investors.p8') }}</div>
    </div>
    <div style="width: 100%;padding:30px 0px; text-align: center;">
      <a style="text-decoration:underline;" @click="goToForm">{{ $t('investors.p9') }}</a>
    </div>
    <zui-footer />
  </div>
</template>
<script>
import "../../less/home-page.less";
import ZuiHeader from "../../../components/Header";
import ZuiFooter from "../../../components/Footer";

export default {
  components: { ZuiHeader, ZuiFooter },
  data() {
    return {};
  },
  methods: {
    goToForm() {
      this.$router.push({
        name: "InvestorsFormPage"
      });
    }
  }

};
</script>

<style lang="scss" scoped>
.banner-box{
  width:100%;
  position: relative;
  .text{
    position: absolute;
    width:100%;
    text-align: center;
    color:#fff;
    top:30%;
    .text-1{
      font-size:34px;
    }

    .text-2{
      font-size:230px;line-height:230px
    }
  }
  .img{
    position: absolute;
    width:100%;
    dispaly:flex;
    justify-content: center;
    align-items: center;
    bottom:10%;
    .logo{
      width:254px;
    }
    .besa{
      width:130px;
      margin-left:48px;
    }
  }
}
.text-box {
  margin-top: 54px;
  color: #fff;
  font-size: 18px;
  line-height: 1.7;
}

.banner-text {
  margin-top: 54px;
  color: #fff;
  font-size: 60px;
  line-height: 1.2;
  font-weight: 100;
}

a {
  display: inline-block;
  color: #fff;
  cursor: pointer;
  margin: 54px 0;
}

@media screen and (max-width: 500px) {
  .text-box {
    margin-top: 32px;
    font-size: 12px;
  }
  .banner-text {
    margin-top: 32px;
    font-size: 32px;
  }
  a {
    margin: 32px 0;
  }
}
@media screen and (max-width: 1700px) {
  .banner-box{
    .text{
      top:22%;

      .text-1{
        font-size:24px;
      }

      .text-2{
        font-size:160px;line-height:180px
      }
    }
    .img{
      bottom:6%;
    }
  }
}
</style>